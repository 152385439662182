import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import WelcomeLayout from './WelcomeLayout';
import Button from '../components/Button';

const backgroundImage = '/images/WebsiteCover.jpg';

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9',
    backgroundPosition: 'center',
  },
  title: {
    position: 'relative',
    marginTop: theme.spacing.unit * 32,
    marginBottom: theme.spacing.unit * 2,
  },
  text: {
    position: 'relative',
    marginBottom: theme.spacing.unit * 4,
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: theme.spacing.unit * 4,
    color: theme.palette.background.paper,
  },
});

class Welcome extends React.Component {

  handleClick = () => {
    window.open("/Sean Michael Lee's Resume.pdf");
  };

  render() {
    const { classes} = this.props;

    return (
      <WelcomeLayout backgroundClassName={classes.background}>
        <img style={{ display: 'none' }} src={backgroundImage} position="fixed" alt="" />
        <Typography color="inherit"  variant="h2" marked="center" className={classes.title}>
          Sean Michael Lee
        </Typography>
        <Typography color="inherit"  variant="h3" className={classes.text}>
          Software Engineer
        </Typography>
        <Button
          align="center"
          size="large"
          className={classes.button}
          onClick={this.handleClick}
        >
          View Resume
        </Button>
      </WelcomeLayout>
    );
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Welcome);
