import React, { Component } from 'react'
import Header from './views/Header'
import Welcome from './views/Welcome'
import About from './views/About'
import Education from './views/Education'
import Experience from './views/Experience'
import Projects from './views/Projects'
import Contact from './views/Contact.js'
import Footer from './views/Footer.js'
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';

class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <Welcome />
          <About />
          <Education />
          <Experience />
          <Projects />
          <Contact />
          <Footer />
        </MuiThemeProvider>
      </div>
    )
  }
}
export default App
