import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Bullet from '@material-ui/icons/Brightness1';
import Paper from '@material-ui/core/Paper';
import ScrollableAnchor from 'react-scrollable-anchor';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    backgroundColor: 'transparent',
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 8,
    marginRight: theme.spacing.unit * 8,
    marginLeft: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 8,
    display: 'flex',
    position: 'relative',
    opacity: 0.8,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing.unit * 4,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.background.paper,
  },
  textDark: {
    color: theme.palette.background.paper,
    display: 'inline',
  },
  textLight: {
    color: theme.palette.background.paper,
    display: 'inline',
  },
  textSecondary: {
    color: theme.palette.secondary.dark,
    display: 'inline',
  },
  list: {
    width: '100%',
  },
  paper: {
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    backgroundColor: theme.palette.secondary.light,
    width: '100%',
  },
});

function Education(props) {
  const { classes } = props;

  return (
    <ScrollableAnchor id={'Education'}>
      <section className={classes.root}>
        <LayoutBody className={classes.layoutBody} width="full">
          <Paper elevation={10} className={classes.paper}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h3" marked="center" className={classes.title}>
                  Education
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.item}>
                    <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textDark}>
                              Computer Science BS
                            </Typography>
                            {" "}
                            <Typography component="span" variant="body1" color="secondary" className={classes.textSecondary}>
                              @
                            </Typography>
                            {" "}
                            <Typography component="span" variant="body1" className={classes.textSecondary}>
                            University of Central Florida
                            </Typography>
                            {" "}
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            (Fall, 2015 - Spring, 2019)
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Bullet color="secondary"/>
                      </ListItemIcon>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            GPA: 3.853 - Cum Laude
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Bullet color="secondary"/>
                      </ListItemIcon>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            Accelerated BS to MS program - Allows high-achieving
                            students to simultaneously earn both a BS and a MS degree.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Bullet color="secondary"/>
                      </ListItemIcon>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            Hack@UCF - A cyber security club promoting offensive and
                            defensive security strategies.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.item}>
                    <List className={classes.list}>
                    <ListItem>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textDark}>
                              Computer Science MS
                            </Typography>
                            {" "}
                            <Typography component="span" variant="body1" color="secondary" className={classes.textSecondary}>
                              @
                            </Typography>
                            {" "}
                            <Typography component="span" variant="body1" className={classes.textSecondary}>
                            University of Central Florida
                            </Typography>
                            {" "}
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            (Fall, 2018 - Spring, 2020)
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Bullet color="secondary"/>
                      </ListItemIcon>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            GPA: 4.0
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Bullet color="secondary"/>
                      </ListItemIcon>
                      <ListItemText
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.textLight}>
                            Expected Graduation Date - May 2019
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </LayoutBody>
      </section>
    </ScrollableAnchor>
  );
}

Education.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Education);
