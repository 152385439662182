import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    backgroundColor: theme.palette.primary.main,
    flexDirection: 'column',
    alignItems: 'center',
  },
  layoutBody: {
    display: 'flex',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    color: theme.palette.background.paper,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

function Footer(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <LayoutBody className={classes.layoutBody} width="full">
        <Typography variant="h5" align="center" marked="center" className={classes.title}>
          Built and Designed by Sean Lee
        </Typography>
      </LayoutBody>
    </section>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
