import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import { configureAnchors } from 'react-scrollable-anchor'; configureAnchors({ offset: 25 });

const styles = theme => ({
  link: {
    marginLeft: theme.spacing.unit * 3,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

function Header(props) {
  const { classes } = props;
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <div className={classes.right}>
            <Link variant="h5" color="inherit" className={classes.link} href='#About'>
              About
            </Link>
            <Link variant="h5" color="inherit" className={classes.link} href="#Education">
              Education
            </Link>
            <Link variant="h5" color="inherit" className={classes.link} href="#Experience">
              Experience
            </Link>
            <Link variant="h5" color="inherit" className={classes.link} href="#Projects">
              Projects
            </Link>
            <Link variant="h5" color="inherit" className={classes.link} href="#Contact">
              Contact
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
