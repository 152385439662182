import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ScrollableAnchor from 'react-scrollable-anchor';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    backgroundColor: 'transparent',
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 8,
    marginRight: theme.spacing.unit * 8,
    marginLeft: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 8,
    display: 'flex',
    position: 'relative',
    opacity: 0.8,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.text.dark,
    marginBottom: theme.spacing.unit * 8,
  },
  textDark: {
    color: theme.palette.text.dark,
    display: 'inline',
  },
  textLight: {
    color: theme.palette.text.light,
    marginBottom: theme.spacing.unit * 4,
  },
  textSecondary: {
    color: theme.palette.secondary.main,
    display: 'inline',
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    position: 'static',
  },
  paper: {
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    width: '100%',
  },
});

class Experience extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <ScrollableAnchor id={'Experience'}>
        <section className={classes.root}>
          <LayoutBody className={classes.layoutBody} width="full">
            <Paper elevation={10} className={classes.paper} >
              <Grid container spacing={32}>
                <Grid item xs={12}>
                  <Typography variant="h3" marked="center" className={classes.title}>
                    Experience
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.tab}>
                    <AppBar className={classes.appBar}>
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                      >
                        <Tab label="General Dynamics Mission Systems" />
                        <Tab label="Harris Corporation" />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={this.state.value}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer dir={theme.direction}>
                        <React.Fragment>
                          <Typography variant="body1" className={classes.textDark}>
                            Software Engineer Intern
                          </Typography>
                          {" "}
                          <Typography variant="body1" color="secondary" className={classes.textSecondary}>
                            @
                          </Typography>
                          {" "}
                          <Typography variant="body1" className={classes.textSecondary}>
                          General Dynamics Mission Systems
                          </Typography>
                          <Typography variant="body1" className={classes.textLight}>
                          (Summer, 2016 - Present)
                          </Typography>
                          <Typography variant="body2" className={classes.textLight}>
                          Collaborated with team members using the Agile Scrum lifecycle
                          to analyze, design, implement, and test an embedded simulation system.
                          Developed C++ service libraries to interface with
                          embedded components and process real-time events,
                          over multiple connection and network protocols. Developed
                          cross-platform software surrogate tools to emulate hardware components
                          for testing. Contributed to the system's high-level architecture via
                          designing new capabilities and UML diagramming.
                          </Typography>
                        </React.Fragment>
                      </TabContainer>
                      <TabContainer dir={theme.direction}>
                        <React.Fragment>
                          <Typography variant="body1" className={classes.textDark}>
                            High School Summer Intern
                          </Typography>
                          {" "}
                          <Typography variant="body1" color="secondary" className={classes.textSecondary}>
                            @
                          </Typography>
                          {" "}
                          <Typography variant="body1" className={classes.textSecondary}>
                          Harris Corporation
                          </Typography>
                          <Typography variant="body1" className={classes.textLight}>
                          (Summer, 2015)
                          </Typography>
                          <Typography variant="body2" className={classes.textLight}>
                          Developed a handheld Wi-Fi based Parrot Drone controller using a beaglebone
                          single board computer with a battery cape. This research project integrated
                          a mix of hardware and software to provide command and control of a Wi-Fi capable
                          Parrot Drone. Hardware integration included wiring and soldering two joystick
                          potentiometers, eight buttons and four RGB LEDs. These hardware components were
                          embedded into an ergonomically designed and 3D printed controller case. The software
                          solution integrated custom code and scripts with Linux, bonescript and NodeJS libraries.
                          The software was optimized to reduce latency in communicating with the drone.
                          </Typography>
                        </React.Fragment>
                      </TabContainer>
                    </SwipeableViews>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </LayoutBody>
        </section>
      </ScrollableAnchor>
    );
  }
}

Experience.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Experience);
