import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
import Button from '../components/Button';
import ScrollableAnchor from 'react-scrollable-anchor';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    backgroundColor: 'transparent',
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 16,
    marginRight: theme.spacing.unit * 8,
    marginLeft: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 8,
    position: 'relative',
    opacity: 0.8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    color: theme.palette.background.paper,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing.unit * 4,
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 60,
    color: theme.palette.background.paper,
  },
});

class Contact extends React.Component {

  handleClick = () => {
    window.location.href='mailto:seanathanmlee@gmail.com?subject=Get In Touch&body=Hello!%20My%20inbox%20is%20always%20open!%20Feel%20free%20to%20say%20hello,%20ask%20questions,%20and%20leave%20feedback.';
  };

  render() {
    const { classes} = this.props;
    return (
      <ScrollableAnchor id={'Contact'}>
        <section className={classes.root}>
          <LayoutBody className={classes.layoutBody} width="full">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h3" align="center" marked="center" className={classes.title}>
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.item}>
                  <Button
                    align="center"
                    size="large"
                    className={classes.button}
                    onClick={this.handleClick}
                  >
                    Get In Touch
                  </Button>
                </div>
              </Grid>
            </Grid>
          </LayoutBody>
        </section>
      </ScrollableAnchor>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
