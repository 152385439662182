import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScrollableAnchor from 'react-scrollable-anchor';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    backgroundColor: 'transparent',
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 8,
    marginRight: theme.spacing.unit * 8,
    marginLeft: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 8,
    position: 'relative',
    opacity: 0.8,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'visible',
    height: '100%',
    width: '100%',
    padding: theme.spacing.unit * 4,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.background.paper,
  },
  textDark: {
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing.unit * 4,
  },
  textLight: {
    color: theme.palette.background.paper,
  },
  card: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    marginBottom: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    height: '100%',
    width: '100%',
  },
  chip: {
    margin: theme.spacing.unit,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.secondary.dark,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  media: {
    width: "100%",
    height: "100%",
  },
  list: {
    width: '100%',
    textAlign: 'center',
  },
  more: {
    color: theme.palette.background.paper,
  },
  panel: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class Projects extends React.Component {
  state = {
    open: true,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes} = this.props;
    return (
      <ScrollableAnchor id={'Projects'}>
        <section className={classes.root}>
          <LayoutBody className={classes.layoutBody} width="full">
            <List className={classes.list}>
              <ListItem>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h3" align="center" marked="center" className={classes.title}>
                    Projects
                  </Typography>
                </Grid>
              </Grid>
              </ListItem>
              <ListItem>
                <div className={classes.item}>
                  <Card className={classes.card}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className={classes.details}>
                          <CardContent className={classes.content}>
                            <Typography variant="body1" className={classes.textLight}>
                              Virtually No Tag
                            </Typography>
                            <Typography variant="body2" className={classes.textDark}>
                            (Project Manager, Backend and Android Developer)
                            </Typography>
                            <Typography variant="body2" className={classes.textLight}>
                            Voted “UCF's Best in Computer Science” senior design project
                            among 50+ project at the 2019 UCF Spring Showcase, Virtually
                            No Tag virtually manages UCF parking permits and optimizes
                            computer vision techniques to uniquely identify permit holders
                            via license plates. Virtually No Tag utilizes a custom implementation
                            of OpenALPR and a convolutional neural network using OpenCV to
                            provide enforcement personnel the ability to identify license
                            plates and determine if the car is eligible to park. Eligibility
                            is based on the users purchasing permits with custom Android and Web
                            applications integrated with a third party payment gateway. These components
                            are tied together with a cloud deployed backend responsible for securely
                            storing permit and violation data.
                            </Typography>
                            <Chip label="AWS EC2" className={classes.chip} />
                            <Chip label="Node.js" className={classes.chip} />
                            <Chip label="NGINX Web Server" className={classes.chip} />
                            <Chip label="Stripe Payment Gateway" className={classes.chip} />
                            <Chip label="React.js" className={classes.chip} />
                            <Chip label="MapBox API" className={classes.chip} />
                            <Chip label="JUnit" className={classes.chip} />
                            <Chip label="Expresso" className={classes.chip} />
                          </CardContent>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <CardMedia className={classes.media} src="iframe"
                        >
                          <React.Fragment>
                            <iframe
                              title="Virtually No Tag"
                              src="https://www.youtube.com/embed/MAT50e682FY?autoplay=1&loop=1&playlist=MAT50e682FY"
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allowFullScreen="1"
                              volume="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              >
                            </iframe>
                          </React.Fragment>
                        </CardMedia>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.item}>
                  <Card className={classes.card}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className={classes.details}>
                          <CardContent className={classes.content}>
                            <Typography variant="body1" className={classes.textLight}>
                              Bus Tracker
                            </Typography>
                            <Typography variant="body2" className={classes.textDark}>
                            (Android Developer)
                            </Typography>
                            <Typography variant="body2" className={classes.textLight}>
                            The Bus Tracker application was a group class project to
                            provide real-time location updates for local bus routes.
                            The application utilized Lynx API's to determine the real-time
                            location, route, and ETA of Lynx buses within the Orlando area.
                            The Bus Tracker allows authenticated users to view specific
                            bus routes and authenticated admins to manage the bus routes
                            users can view.
                            </Typography>
                            <Chip label="Android" className={classes.chip} />
                            <Chip label="Google Firebase" className={classes.chip} />
                            <Chip label="Google Maps API" className={classes.chip} />
                          </CardContent>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <CardMedia className={classes.media} src="iframe"
                        >
                          <React.Fragment>
                            <iframe
                              title="Bus Tracker"
                              src="https://www.youtube.com/embed/HilCTPdkzho?autoplay=1&loop=1&playlist=HilCTPdkzho"
                              width="100%"
                              height="100%"
                              frameBorder="0"
                              allowFullScreen="1"
                              volume="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              >
                            </iframe>
                          </React.Fragment>
                        </CardMedia>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <ExpansionPanel className={classes.panel}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.textLight} />}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Typography variant="h4" align="center" className={classes.more}>
                            More
                          </Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <div className={classes.item}>
                            <Card className={classes.card}>
                              <div className={classes.details}>
                                <CardContent className={classes.content}>
                                  <Typography variant="body1" className={classes.textLight}>
                                    Lab Inventory Tool
                                  </Typography>
                                  <Typography variant="body2" className={classes.textDark}>
                                  (Android Developer)
                                  </Typography>
                                  <Typography variant="body2" className={classes.textLight}>
                                  Awarded General Dynamics Missions Systems (Orlando) Engineering Week Codeathon Winners.
                                  Collaborated with a co-worker to develop an Android application for virtually tracking
                                  and managing lab equipment. The application allows authenticated users to checkout and
                                  return items from a lab's inventory using the device's serial number or NFC tag. The
                                  applications allows a lab admin to add and delete items from the inventory, approve user returns,
                                  and determine the user of checked out equipment.
                                  </Typography>
                                  <Chip label="Android" className={classes.chip} />
                                  <Chip label="Google Firebase" className={classes.chip} />
                                </CardContent>
                              </div>
                            </Card>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.item}>
                            <Card className={classes.card}>
                              <div className={classes.details}>
                                <CardContent className={classes.content}>
                                  <Typography variant="body1" className={classes.textLight}>
                                    Harris Corporation Design Challenge
                                  </Typography>
                                  <Typography variant="body2" className={classes.textDark}>
                                  (Android Developer)
                                  </Typography>
                                  <Typography variant="body2" className={classes.textLight}>
                                  Awarded 1st place honors in the Harris Corporation Design Challenge. Developed an android phone
                                  application to aid the user in solving mathematical and environmental problems. Developed a graphical
                                  user interface that utilized the phone’s accelerometer, GPS, timer and camera to measure angles,
                                  sense vibrations, find and plot location coordinates, measure distances and the size of objects. The
                                  phone application allowed the user to complete a series of dependent tasks to solve a larger problem.
                                  </Typography>
                                  <Chip label="Android" className={classes.chip} />
                                </CardContent>
                              </div>
                            </Card>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.item}>
                            <Card className={classes.card}>
                              <div className={classes.details}>
                                <CardContent className={classes.content}>
                                  <Typography variant="body1" className={classes.textLight}>
                                    Professional Portfolio
                                  </Typography>
                                  <Typography variant="body2" className={classes.textDark}>
                                  (Web Developer)
                                  </Typography>
                                  <Typography variant="body2" className={classes.textLight}>
                                  Created a professional website portfolio as a personal project to learn the principles
                                  of web development and current technologies. The cloud deployed website integrates
                                  a Node.js backend environment, hosted on Amazon Web Services Elastic Compute Cloud Instance (EC2),
                                  with an interactive frontend. The frontend utilizes the React.js web framework and the Material
                                  UI library to present additional portfolio information that goes beyond the condensed resume.
                                  </Typography>
                                  <Chip label="AWS EC2" className={classes.chip} />
                                  <Chip label="Node.js" className={classes.chip} />
                                  <Chip label="NGINX Web Server" className={classes.chip} />
                                  <Chip label="React.js" className={classes.chip} />
                                  <Chip label="Material UI" className={classes.chip} />
                                </CardContent>
                              </div>
                            </Card>
                          </div>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </ListItem>
            </List>
          </LayoutBody>
        </section>
      </ScrollableAnchor>
    );
  }
}

Projects.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Projects);
