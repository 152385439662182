import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Bullet from '@material-ui/icons/Brightness1';
import ScrollableAnchor from 'react-scrollable-anchor';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'visible',
    backgroundColor: 'transparent',
  },
  layoutBody: {
    marginTop: theme.spacing.unit * 24,
    marginRight: theme.spacing.unit * 8,
    marginLeft: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 8,
    display: 'flex',
    position: 'relative',
    opacity: 0.8,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing.unit * 4,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.text.dark,
  },
  textDark: {
    color: theme.palette.text.dark,
  },
  textLight: {
    color: theme.palette.text.light,
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    width: '100%',
  },
});

function About(props) {
  const { classes } = props;

  return (
  <ScrollableAnchor id={'About'}>
    <section className={classes.root}>
      <LayoutBody className={classes.layoutBody} width="full">
        <Paper elevation={10} className={classes.paper}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" marked="center" className={classes.title}>
                About
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.item}>
                <Typography variant="body1" className={classes.textDark}>
                  Welcome, I’m Sean Lee! I created this professional portfolio
                  to provide insight about my passion for creating efficient
                  software. The following are some relevant skills I have acquired,
                  but I’m always attempting to learn new technologies to perfect my craft:
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.item}>
                  <List className={classes.list}>
                  <ListItem>
                    <ListItemIcon>
                      <Bullet color="secondary"/>
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography component="span" variant="body1" className={classes.textLight}>
                          Programming Languages: (Proficient) C++, Java,
                           and C; (Familiar) JavaScript and C#
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Bullet color="secondary"/>
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography component="span" variant="body1" className={classes.textLight}>
                          Applications and Tools: (Subversion) Tortoise SVN and Git;
                           (Platforms) Windows, Linux, Android; (Software Design) Agile Methodology
                            and UML Diagramming; (Frameworks) Qt and React; (Cloud Services) AWS and Google Firebase
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </LayoutBody>
    </section>
  </ScrollableAnchor>
  );
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
